import { useCustomer } from '@/app/context/CustomerContext/useCustomer';
import React from 'react';

interface IProps {
  title: string;
  monthlyAmount?: string;
  yearlyAmmount?: string;
  description: string;
  features: string[];
  submitText?: string;
  staticText?: string;
  isRightSide?: boolean;
  onSubmit: () => void;
  isMonthly: boolean;
}

export default function PricingCard(props: IProps) {
  const { isProEligible, country } = useCustomer();

  let amount = 'Free';
  let period = '';
  if (props.isMonthly && props.monthlyAmount) {
    amount = props.monthlyAmount;
    period = '/month';
  }

  if (!props.isMonthly && props.yearlyAmmount) {
    amount = props.yearlyAmmount;
    period = '/year';
  }

  return (
    <div
      className={` mb-8 flex w-full justify-center  sm:px-4 md:w-1/2 ${
        props.isRightSide
          ? 'md:justify-start'
          : isProEligible
          ? 'md:justify-end'
          : 'md:justify-center'
      } lg:mb-0 lg:w-1/2`}
    >
      <div
        style={{ width: 375 }}
        className="boder-gray-50 bordered flex flex-col space-y-6 rounded border-2 border-solid bg-base-100 p-6 shadow sm:p-8"
      >
        <div className="space-y-2">
          <h3 className="text-2xl font-bold">{props.title}</h3>
          <span className="text-6xl font-bold">
            {amount}
            <span className="tracki text-sm">
              {props.isMonthly &&
                props.monthlyAmount &&
                `${country.currencySuffix} / month`}
              {!props.isMonthly &&
                props.yearlyAmmount &&
                `${country.currencySuffix} / year`}
            </span>
          </span>
        </div>
        <p className="leadi">{props.description}</p>

        <ul className="flex-1 space-y-2">
          {(props.monthlyAmount || props.yearlyAmmount) && (
            <li className="font-bold">Everything in free, plus...</li>
          )}
          {props.features.map((feature) => (
            <li key={feature} className="flex items-center space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-6 w-6 flex-shrink-0"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>{feature}</span>
            </li>
          ))}
        </ul>
        {props.submitText && (
          <div
            onClick={props.onSubmit}
            className="inline-block w-full cursor-pointer rounded bg-primary px-5 py-3 text-center font-bold shadow transition duration-300 ease-in-out hover:scale-105"
          >
            {props.submitText}
          </div>
        )}
        {props.staticText && (
          <div
            onClick={props.onSubmit}
            className="bordered inline-block w-full rounded border-2 border-white bg-gray-800 px-5 py-3 text-center font-bold shadow"
          >
            {props.staticText}
          </div>
        )}
      </div>
    </div>
  );
}
