import PricingCard from './pricing-card/PricingCard';
import { useState } from 'react';
import { useAuth } from '@/app/context/AuthContext/useAuth';
import { useCustomer } from '@/app/context/CustomerContext/useCustomer';
import { useAnalytics } from '@/app/context/AnalyticsContext/useAnalytics';
import { CheckIcon } from '@heroicons/react/20/solid';
import { Radio, RadioGroup } from '@headlessui/react';

const frequencies = [
  { value: 'monthly', label: 'Monthly' },
  { value: 'annually', label: 'Annually' }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function PurchasingOptions() {
  const { showAuth } = useAuth();
  const { isProEligible, country } = useCustomer();
  const { trackEvent, analyticsInstance } = useAnalytics();

  const [frequency, setFrequency] = useState(frequencies[0]);

  const isMonthly = frequency.value === 'monthly';

  const tiers = [
    {
      name: 'Free',
      id: 'tier-hobby',
      href: '/app/account',
      priceMonthly: `${country.currencySymbol}0${country.currencySuffix}`,
      priceYearly: `${country.currencySymbol}0${country.currencySuffix}`,
      description:
        "The perfect plan if you're just getting started with Limbiks.",
      features: [
        '10 Uploads / month',
        'Select up to 5 pages or slides',
        'Study unlimited flashcards',
        'Download Flashcards',
        'Limited access to most features'
      ],
      featured: false
    },
    {
      name: 'Pro',
      id: 'pro',
      href: '/app/account?interval=month&source=pricing',
      priceMonthly: `${country.currencySymbol}${country.monthlyRate}${country.currencySuffix}`,
      priceYearly: `${country.currencySymbol}${country.yearlyRate}${country.currencySuffix}`,
      description:
        'Fully optimized studying with unlimited access to every Limbiks feature.',
      features: [
        '100 uploads / month',
        'Select up to 200 pages or slides',
        'AI Generated Study Guides',
        'Unlimited practice tests',
        'Unlimited multiple choice questions',
        'and more...'
      ],
      featured: true
    }
  ];

  if (!isProEligible) {
    return (
      <section className="flex justify-center bg-gray-50 py-20">
        <PricingCard
          title="Beginner"
          description="Free plan with limited uploads and full access to most Limbiks features."
          features={[
            '10 Uploads / month',
            'Select up to 10 pages or slides',
            'Download Flashcards',
            'Flashcards Study Tool',
            'Quiz Questions Study Tool'
          ]}
          submitText="Sign Up"
          onSubmit={() => {
            trackEvent(
              analyticsInstance,
              'home_pricing_ineligible_click_sign_up',
              {
                isMonthly
              }
            );
            showAuth(true);
          }}
          isMonthly={isMonthly}
        />
      </section>
    );
  }

  return (
    <div className="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div
        aria-hidden="true"
        className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl"
      >
        <div
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'
          }}
          className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#0088f8] to-[tomato] opacity-30"
        />
      </div>
      <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Pricing
        </h2>
        <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Choose your plan
        </p>
      </div>
      <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
        Upgrade to Limbiks Pro to get more uploads, more pages and slides per
        upload, and full access to every Limbiks feature.
      </p>
      <div className="mt-16 flex justify-center">
        <fieldset aria-label="Payment frequency">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            {frequencies.map((option) => {
              return (
                <Radio
                  key={option.value}
                  value={option}
                  className={`${
                    option === frequency ? 'bg-primary text-white' : ''
                  } cursor-pointer rounded-full px-2.5 py-1 text-gray-500`}
                >
                  {option.label}
                </Radio>
              );
            })}
          </RadioGroup>
        </fieldset>
      </div>
      <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
        {tiers.map((tier, tierIdx) => (
          <div
            key={tier.id}
            className={classNames(
              tier.featured
                ? 'relative bg-gray-900 shadow-2xl'
                : 'bg-white/60 sm:mx-8 lg:mx-0',
              tier.featured
                ? ''
                : tierIdx === 0
                ? 'rounded-t-3xl sm:rounded-b-none lg:rounded-bl-3xl lg:rounded-tr-none'
                : 'sm:rounded-t-none lg:rounded-bl-none lg:rounded-tr-3xl',
              'rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10'
            )}
          >
            <h3
              id={tier.id}
              className={classNames(
                tier.featured ? 'text-white' : 'text-gray-900',
                'text-base font-semibold leading-7'
              )}
            >
              {tier.name}
            </h3>
            <p className="mt-4 flex items-baseline gap-x-2">
              <span
                className={classNames(
                  tier.featured ? 'text-white' : 'text-gray-900',
                  'text-5xl font-bold tracking-tight'
                )}
              >
                {isMonthly ? tier.priceMonthly : tier.priceYearly}
              </span>
              <span
                className={classNames(
                  tier.featured ? 'text-gray-400' : 'text-gray-500',
                  'text-base'
                )}
              >
                / {isMonthly ? 'month' : 'year'}
              </span>
            </p>
            <p
              className={classNames(
                tier.featured ? 'text-gray-300' : 'text-gray-600',
                'mt-6 text-base leading-7'
              )}
            >
              {tier.description}
            </p>
            <ul
              role="list"
              className={classNames(
                tier.featured ? 'text-gray-300' : 'text-gray-600',
                'mt-8 space-y-3 text-sm leading-6 sm:mt-10'
              )}
            >
              {tier.featured && (
                <li className="flex gap-x-3 font-semibold">
                  Everything in free, plus...
                </li>
              )}
              {tier.features.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon
                    aria-hidden="true"
                    className={classNames(
                      tier.featured ? 'text-white' : 'text-gray-900',
                      'h-6 w-5 flex-none'
                    )}
                  />
                  {feature}
                </li>
              ))}
            </ul>
            <div
              onClick={() => {
                if (!tier.featured) {
                  trackEvent(
                    analyticsInstance,
                    'home_pricing_click_free_sign_up',
                    {
                      isMonthly
                    }
                  );
                  showAuth(true);
                } else {
                  trackEvent(
                    analyticsInstance,
                    'home_pricing_click_pro_subscribe',
                    {
                      isMonthly
                    }
                  );
                  trackEvent(analyticsInstance, 'upgrade_click', {
                    upgrade_source: 'home_pricing'
                  });
                  showAuth(true, {
                    pathname: '/app/account',
                    query: {
                      source: 'home_pricing'
                    }
                  });
                }
              }}
              aria-describedby={tier.id}
              className={classNames(
                tier.featured
                  ? 'bg-primary text-white shadow-sm hover:bg-primary hover:opacity-90 focus-visible:outline-primary'
                  : 'text-primary ring-1 ring-inset ring-gray-200 hover:ring-gray-300 focus-visible:outline-primary',
                'mt-8 block cursor-pointer rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10'
              )}
            >
              {tier.featured ? 'Subscribe' : 'Sign Up'}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  // if (!isProEligible) {
  //   return (
  //     <section className="flex justify-center bg-gray-50 py-20">
  //       <PricingCard
  //         title="Beginner"
  //         description="Free plan with limited uploads and full access to most Limbiks features."
  //         features={[
  //           '10 Uploads / month',
  //           'Select up to 10 pages or slides',
  //           'Download Flashcards',
  //           'Flashcards Study Tool',
  //           'Quiz Questions Study Tool'
  //         ]}
  //         submitText="Sign Up"
  //         onSubmit={() => {
  //           trackEvent(
  //             analyticsInstance,
  //             'home_pricing_ineligible_click_sign_up',
  //             {
  //               isMonthly
  //             }
  //           );
  //           showAuth(true);
  //         }}
  //         isMonthly={isMonthly}
  //       />
  //     </section>
  //   );
  // }

  // return (
  //   <section className="bg-gray-50 py-20 ">
  //     <div className="container mx-auto px-4">
  //       <PeriodToggle
  //         onToggle={(isMonthly) => setIsMonthly(isMonthly)}
  //         isMonthly={isMonthly}
  //       />

  //       <div className="-mx-4 flex flex-wrap items-stretch">
  //         <PricingCard
  //           title="Beginner"
  //           description="Free plan with limited uploads and basic access to most Limbiks features."
  //           features={[
  //             '10 Uploads / month',
  //             'Select up to 5 pages or slides',
  //             'Download Flashcards',
  //             'Study unlimited flashcards',
  //             '10 practice tests / month',
  //             '100 multiple choice questions / month'
  //           ]}
  //           submitText="Sign Up"
  //           onSubmit={() => {
  //             trackEvent(analyticsInstance, 'home_pricing_click_free_sign_up', {
  //               isMonthly
  //             });
  //             showAuth(true);
  //           }}
  //           isMonthly={isMonthly}
  //         />
  //         <PricingCard
  //           title="Pro"
  //           monthlyAmount={`${country.currencySymbol}${country.monthlyRate}`}
  //           yearlyAmmount={`${country.currencySymbol}${country.yearlyRate}`}
  //           description="More uploads, more pages and slides, and full access to every Limbiks feature."
  //           features={[
  //             '100 uploads / month',
  //             'Select up to 200 pages or slides',
  //             'AI Generated Study Guides',
  //             'Unlimited practice tests',
  //             'Unlimited multiple choice questions'
  //           ]}
  //           submitText="Subscribe"
  //           onSubmit={() => {
  //             trackEvent(
  //               analyticsInstance,
  //               'home_pricing_click_pro_subscribe',
  //               {
  //                 isMonthly
  //               }
  //             );
  //             trackEvent(analyticsInstance, 'upgrade_click', {
  //               upgrade_source: 'home_pricing'
  //             });
  //             showAuth(true, {
  //               pathname: '/app/account',
  //               query: {
  //                 source: 'home_pricing'
  //               }
  //             });
  //           }}
  //           isRightSide
  //           isMonthly={isMonthly}
  //         />
  //       </div>
  //       {isProEligible && (
  //         <div className="mt-12 text-center text-black">
  //           See the full breakdown of{' '}
  //           <span
  //             onClick={() => {
  //               trackEvent(analyticsInstance, 'home_pricing_click_breakdown');
  //             }}
  //             className="link text-primary"
  //           >
  //             <Link href={'/pricing'}>pricing and features</Link>
  //           </span>
  //         </div>
  //       )}
  //     </div>
  //   </section>
  // );
}
